.content-bg {
  background-color: #282c34;
  min-height: 100vh;
  min-width: 100vw;
}

.content {
  width: 60vw;
  display: flex;
  flex-direction: column;
  position: relative;
  left: 20vw;
}

.content-list {
  list-style: revert;
  padding: revert;
  margin: revert;
}

.content-code {
  white-space: pre-wrap;
}

.iframe-container {
  display: flex;
  justify-content: center;
}

.img {
  align-self: center;
  max-width: 55vw;
  max-height: 55vh;
  border-radius: 20px;
  margin-top: 5vh;
  margin-bottom: 2vh;
}

.content-paragraph {
  padding: 4px;
}

.content-heading-2 {
    text-align: center;
}

.content-yt-embed {
  width: 35vw;
  height: calc(35vw * 0.75);
}

